import React from "react";
import Navbar from "../components/Navbar";
import Heading from "../components/UI/Heading";
import TopHeader from "../components/UI/TopHeader";
import Service from "../components/serviceUi/Service";
import Support from "../components/serviceUi/Support";
import Clints from "../components/serviceUi/Clints";
import GetStarted from "../components/serviceUi/GetStarted";
import Footer from "../components/serviceUi/Footer";
import Process from "../components/serviceUi/Process";
import BacktoTop from "../components/shortCSS/BacktoTop";
import strings from '../constants/strings';
import Strip from '../components/serviceUi/Strip';

const Services4 = () => {
  const data = [
    {
      id: 1,
      iconNumber: 1,
      title: "HTML & CSS Responsive Web Design",
      description: "HTML & CSS Responsive Web Design",
      link: "",
      themeColor: "#0064fb",
      themeSmallColor: "#e5f0ff",
      textColor: "#fff",
      icon: <svg className="w-64 h-64" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor">
        <g>
          <path fill="none" d="M0 0h24v24H0z"></path>
          <path d="M6.94 14.036c-.233.624-.43 1.2-.606 1.783.96-.697 2.101-1.139 3.418-1.304 2.513-.314 4.746-1.973 5.876-4.058l-1.456-1.455 1.413-1.415 1-1.001c.43-.43.915-1.224 1.428-2.368-5.593.867-9.018 4.292-11.074 9.818zM17 9.001L18 10c-1 3-4 6-8 6.5-2.669.334-4.336 2.167-5.002 5.5H3C4 16 6 2 21 2c-1 2.997-1.998 4.996-2.997 5.997L17 9.001z"></path>
        </g>
      </svg>,
    },
    {
      id: 2,
      iconNumber: 2,
      title: "React JS Development",
      description: "Webneuron offering you the best digital marketing services to just like your personal identity makes you uniquely you, your brand identity is the special sauce of your to our customer.",
      link: "",
      themeColor: "#36cc72",
      themeSmallColor: "#ebfaf1",
      textColor: "#fff",
      icon: <svg className="w-64 h-64" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor">
        <g>
          <path fill="none" d="M0 0h24v24H0z"></path>
          <path d="M12 22C6.477 22 2 17.523 2 12c0-4.478 2.943-8.268 7-9.542v2.124A8.003 8.003 0 0 0 12 20a8.003 8.003 0 0 0 7.418-5h2.124c-1.274 4.057-5.064 7-9.542 7zm9.95-9H11V2.05c.329-.033.663-.05 1-.05 5.523 0 10 4.477 10 10 0 .337-.017.671-.05 1zM13 4.062V11h6.938A8.004 8.004 0 0 0 13 4.062z"></path>
        </g>
      </svg>,
    },
    {
      id: 3,
      iconNumber: 3,
      title: "Node.js Development",
      description: "Webneuron offering you the best digital marketing services to just like your personal identity makes you uniquely you, your brand identity is the special sauce of your to our customer.",
      link: "",
      themeColor: "#ff414b",
      themeSmallColor: "#ffeced",
      textColor: "#fff",
      icon: <svg className="w-64 h-64" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor">
        <g>
          <path fill="none" d="M0 0h24v24H0z"></path>
          <path d="M9.973 18H11v-5h2v5h1.027c.132-1.202.745-2.194 1.74-3.277.113-.122.832-.867.917-.973a6 6 0 1 0-9.37-.002c.086.107.807.853.918.974.996 1.084 1.609 2.076 1.741 3.278zM10 20v1h4v-1h-4zm-4.246-5a8 8 0 1 1 12.49.002C17.624 15.774 16 17 16 18.5V21a2 2 0 0 1-2 2h-4a2 2 0 0 1-2-2v-2.5C8 17 6.375 15.774 5.754 15z"></path>
        </g>
      </svg>,
    },
    {
      id: 4,
      iconNumber: 4,
      title: "Javascript Development",
      description: "Webneuron offering you the best digital marketing services to just like your personal identity makes you uniquely you, your brand identity is the special sauce of your to our customer.",
      link: "", themeColor: "#ff6d3d",
      themeSmallColor: "#fff0ec",
      textColor: "#fff",
      icon: <svg className="w-64 h-64" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor">
        <g>
          <path fill="none" d="M0 0h24v24H0z"></path>
          <path d="M19.938 8H21a2 2 0 0 1 2 2v4a2 2 0 0 1-2 2h-1.062A8.001 8.001 0 0 1 12 23v-2a6 6 0 0 0 6-6V9A6 6 0 1 0 6 9v7H3a2 2 0 0 1-2-2v-4a2 2 0 0 1 2-2h1.062a8.001 8.001 0 0 1 15.876 0zM3 10v4h1v-4H3zm17 0v4h1v-4h-1zM7.76 15.785l1.06-1.696A5.972 5.972 0 0 0 12 15a5.972 5.972 0 0 0 3.18-.911l1.06 1.696A7.963 7.963 0 0 1 12 17a7.963 7.963 0 0 1-4.24-1.215z"></path>
        </g>
      </svg>,
    },
    {
      id: 5,
      iconNumber: 5,
      title: "PSD to bootstrap services",
      description: "Webneuron offering you the best digital marketing services to just like your personal identity makes you uniquely you, your brand identity is the special sauce of your to our customer.",
      link: "", themeColor: "#8932f8",
      themeSmallColor: "#f3ebfe",
      textColor: "#fff",
      icon: <svg className="w-64 h-64" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor">
        <g>
          <path fill="none" d="M0 0h24v24H0z"></path>
          <path d="M17 21H7A6 6 0 0 1 5.008 9.339a7 7 0 1 1 13.984 0A6 6 0 0 1 17 21zm0-12a5 5 0 1 0-9.994.243l.07 1.488-1.404.494A4.002 4.002 0 0 0 7 19h10a4 4 0 1 0-3.796-5.265l-1.898-.633A6.003 6.003 0 0 1 17 9z"></path>
        </g>
      </svg>,
    },
    {
      id: 6,
      iconNumber: 6,
      title: "CMS Theming Services",
      description: "Webneuron offering you the best digital marketing services to just like your personal identity makes you uniquely you, your brand identity is the special sauce of your to our customer.",
      link: "", themeColor: "#ffca40",
      themeSmallColor: "#fffaec",
      textColor: "#fff",
      icon: <svg className="w-64 h-64" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor">
        <g>
          <path fill="none" d="M0 0h24v24H0z"></path>
          <path
            fillRule="nonzero"
            d="M19 11V5H5v6h14zm0 2H5v6h14v-6zM4 3h16a1 1 0 0 1 1 1v16a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V4a1 1 0 0 1 1-1z"
          ></path>
        </g>
      </svg>
    },
  ]
  return (
    <div className="ServicesPages" id="ServicePages">
      {/* <TopHeader /> */}
      <Navbar />
      <Heading subject={strings.service4.heading} />
      <div className="container">
        <Service data={data} />
      </div>
      <Process />
      <Strip title={"We would love to hear about your project. Get the best services for Mobile App Development"} />
      <Support strings={strings.service4} />
      <Clints />
      <GetStarted />
      <BacktoTop />
      <Footer />
    </div>
  );
};

export default Services4;
