const strings = {
    // Home 
    header: {
        heading: 'We are here to answer customer needs, creating new solutions and delivering innovation.',
        description: 'We have a proven track record of delivering high-quality IT services to a diverse range of clients, including small businesses, startups, and large enterprises. We strive to stay ahead of the curve by continuously updating our knowledge and adopting the latest technologies and industry best practices.',
    },
    service: {
        card1Heading: 'Web and App Development',
        card2Heading: 'Front End Development',
        card3Heading: 'Blockchain Development Services',
        card1content: 'By Accessing a wide range of tools and technologies, we create customized web applications and designs which are class-leading and scalable, and offer attractive ROI.',
        card2content: 'Headed to convey projects with the most competitive level of incredible skill and at cutthroat rates, we utilize the most recent instruments and structures, for example, Angular, React.js, etc to make quick loading and user friendly interfaces.',
        card3content: 'We have the expertise in delivering personalized blockchain solutions for small, medium and large businesses. We provide  end-to-end blockchain development services that include consulting, designing and development, majorly focused on helping clients to achieve maximum ROI.',
    },
    experience: {
        heading: "About Us",
        content: "With 14+ years of experience, Webneuron has expertise in developing and creating complex and sophisticated Web-based applications and Software. We are working with a team size of 15 members who are outcome-focused, strategically aware, self-motivated, multi-task oriented, adaptable, collaborative, and risk-taking professionals. We have continually sought to reinvent and improve ourselves in line with the latest technology to serve our clients in the best possible manner.",
        point1: "Our client-centric approach ensures that we thoroughly understand our client's unique needs and tailor our solutions to meet their specific requirements, budgets, and timelines.",
        point2: "Over the years, we have successfully completed numerous projects, delivering scalable and cost-effective solutions that drive business growth and enhance operational efficiency.",
        point3: "We have a strong commitment to quality, and our rigorous quality assurance processes ensure that all our deliverables meet the highest standards of performance, reliability, and security.",
    },
    offeredServices: {
        card1: {
            heading: "Design and Development",
            content: "We offer a result oriented look and feel by making it a visually appealing website from a user's perspective. We have a clear goal in our mind that is to turn your website into a high-performing business.",
        },
        card2: {
            heading: "Framework Base Development",
            content: "It is a real or conceptual structure intended to serve as a support or guide for the building of something that expands the structure.",
        },
        card3: {
            heading: "Custom Software Development",
            content: "This is the process of creating software applications that are designed and built specifically for a particular organization or individual.",
        },
        card4: {
            heading: "Hubspot Development",
            content: "0Our Hubspot Development services aims at taking your traditional business to the next level web architecture approach. We offer the growth-driven result in an attractive budget.",
        },
        card5: {
            heading: "SAAS Solutions",
            content: "Where software applications are delivered and accessed through the internet, instead of purchasing and installing on local servers/computers.",
        },
        card6: {
            heading: "End to End Solutions",
            content: "From planning to implementation, deploying, and maintaining  the end product, we serve you with well organized and professional services as per your growth and business requirements.",
        },
    },
    support: {
        heading: 'Front End Development.',
        content: 'Headed to convey projects with the most competitive level of incredible skill and at cutthroat rates, we utilize the most recent instruments and structures, for example, Angular, React.js, etc to make quick loading and user friendly interfaces.',
    },
    // Service
    service1: {
        heading: 'Web Application Development',
        support: {
            heading: 'Web Application Development',
            content: 'By Accessing a wide range of tools and technologies, we create customized web applications and designs which are class-leading and scalable, and offer attractive ROI.',
        }
    },
    service2: {
        heading: 'Blockchain based Solutions / NFT',
        support: {
            heading: 'Blockchain Development Services',
            content: 'We have the expertise in delivering personalized blockchain solutions for small, medium and large businesses. We provide end-to-end blockchain development services that include consulting, designing and development, majorly focused on helping clients to achieve maximum ROI.',
        }
    },
    service3: {
        heading: 'Mobile App Development',
        support: {
            heading: 'Mobile App Development',
            content: 'We have the expertise in delivering personalized blockchain solutions for small, medium and large businesses. We provide end-to-end blockchain development services that include consulting, designing and development, majorly focused on helping clients to achieve maximum ROI.',
        }
    },
    service4: {
        heading: 'Front End Development',
        support: {
            heading: 'Front End Development',
            content: 'Headed to convey projects with the most competitive level of incredible skill and at cutthroat rates, we utilize the most recent instruments and structures, for example, Angular, React.js, etc to make quick loading and user friendly interfaces.',
        }
    },
}

export default strings;