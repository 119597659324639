import React, { useEffect } from "react";
import styles from "../styles/ServiceDetail.module.css";
import { ArrowForward } from "@mui/icons-material";
import ServiceDetailCard from './Home/ServiceDetailCard';
import strings from '../constants/strings';

const ServiceDetail = () => {
  useEffect(() => {
    document
      .getElementById("ServiceDetail")
      .addEventListener("mousemove", parallax);
    function parallax(e) {
      document.querySelectorAll(".mouseAnimation").forEach((layer) => {
        const x = (e.pageX * 1) / 16;
        const y = (e.pageY * 1) / 30;

        layer.style.transition = "all 0.25s linear";
        layer.style.transform = `translateY(${y}px) translateX(${x}px) `;
      });
    }

    return () => {
      document.removeEventListener("mousemove", () => { });
    };
  }, []);
  return (
    <div className={styles.ServiceDetail} id="ServiceDetail">
      <div className={styles.ServiceDetailBox}>
        <ServiceDetailCard heading={strings.service.card1Heading} description={strings.service.card1content} imageUrl={"/Images/features/features-1.webp"} link={'/services1'} />
        <ServiceDetailCard heading={strings.service.card2Heading} description={strings.service.card2content} imageUrl={"/Images/features/features-1.webp"} link={'/services4'} >
          <div className="Shapes">
            <img
              src="/Images/shape-5(2).png"
              className={`mouseAnimation ${styles.shape5}`}
              alt=""
            />
            <img
              src="/Images/shape-4(2).png"
              className={`mouseAnimation ${styles.shape4}`}
              alt=""
            />
            <img
              src="/Images/shape-4.png"
              className={`moveAny ${styles.shape41}`}
              alt=""
            />
            <img
              src="/Images/shape-3.png"
              className={` ${styles.moveFast} ${styles.shape41}`}
              alt=""
            />
          </div>
        </ServiceDetailCard>
        <ServiceDetailCard heading={strings.service.card3Heading} description={strings.service.card3content} imageUrl={"/Images/features/features-1.webp"} link={'/services2'} />
      </div>
    </div>
  );
};

export default ServiceDetail;
