import { ArrowForward } from "@mui/icons-material";
import React, { useEffect } from "react";
import styles from "../../styles/Service.module.css";
import ServiceCard from './ServiceCard';

const Service = ({ data }) => {
  useEffect(() => {
    document.getElementById("Service").addEventListener("mousemove", parallax);
    function parallax(e) {
      document.querySelectorAll(".moveAnimations").forEach((layer) => {
        const x = (e.pageX * 1) / 30;
        const y = (e.pageY * 1) / 60;

        layer.style.transition = "all 0.4s linear";
        layer.style.transform = `translateY(${y}px) translateX(${x}px) `;
      });
    }

    return () => {
      document.removeEventListener("mousemove", () => { });
    };
  }, []);
  useEffect(() => {
    document.getElementById("Service").addEventListener("mousemove", parallax);
    function parallax(e) {
      document.querySelectorAll(".moveAnimations2").forEach((layer) => {
        const x = (e.pageX * 1) / 30;
        const y = (e.pageY * 1) / 60;

        layer.style.transition = "all 0.4s linear";
        layer.style.transform = `translateY(${y}px) translateX(${x}px) scaleX(-1) scaleY(-1) rotate(-30deg) `;
      });
    }

    return () => {
      document.removeEventListener("mousemove", () => { });
    };
  }, []);

  return (
    <div
      className={styles.service}
      id="Service"
      style={{ overflow: "visible" }}
    >
      <div className={styles.service_content}>
        <div className={styles.service_grid}>
          {data.map((item, key) => <ServiceCard key={key} data={item} />)}
        </div>
      </div>
      <div className={styles.shapes}>
        <img
          src="/Images/shape-4.png"
          className="moveAny"
          style={{
            position: "absolute",
            top: "24%",
            left: "40%",
            zIndex: "1",
          }}
          alt=""
        />
        <div
          className="shape2 moveAnimations"
          style={{
            position: "absolute",
            top: "20%",
            left: "45%",
            objectFit: "contain",
            zIndex: "1",
          }}
        >
          <img
            src="/Images/shape-3.png"
            className=" rotateInfi"
            style={{
              position: "absolute",
              objectFit: "contain",
              zIndex: "1",
            }}
            alt=""
          />
        </div>
        <img
          src="/Images/shape-2.png"
          className=" moveAnimations2"
          style={{
            position: "absolute",
            bottom: "56%",
            left: "-4%",
            objectFit: "contain",
            maxWidth: "50px",
            zIndex: "-1",
          }}
          alt=""
        />
        <img
          src="/Images/service/shape-4.webp"
          className=" moveAnimations"
          style={{
            position: "absolute",
            bottom: "38%",
            right: "0",
            zIndex: "1",
          }}
          alt=""
        />
      </div>
    </div>
  );
};

export default Service;

/* <img src="/Images/service/quill-pen.svg" alt="" />; */
