import React, { useEffect } from "react";
import styles from "../../styles/Footer.module.css";
import { Link } from 'react-router-dom';

const Footer = ({ aboutRef }) => {
  useEffect(() => {
    document.getElementById("footer").addEventListener("mousemove", parallax);
    function parallax(e) {
      document.querySelectorAll(".footerAnimation").forEach((layer) => {
        const x = (e.pageX * 1) / 14;
        const y = (e.pageY * 1) / 10;

        layer.style.transition = "all 0.5s linear";
        layer.style.transform = `translateY(${y}px) translateX(${x}px) `;
      });
    }

    return () => {
      document.removeEventListener("mousemove", () => { });
    };
  }, []);
  const scrollToAbout = e => {
    if (aboutRef?.current) {
      window.scrollTo({ top: aboutRef.current.offsetTop, behavior: "smooth" });
    }
  }
  return (
    <footer className={styles.footer} id="footer">
      <div className={styles.upperFooter}>
        <div className="shapes" style={{ position: "relative" }}>
          <img
            src="/Images/footer/shape-1.webp"
            className="footerAnimation"
            style={{
              position: "absolute",
              right: "130px",
              top: "-400px",
            }}
            alt=""
          />
          <div
            className="shape2 footerAnimation"
            style={{
              position: "absolute",
              top: "-235px",
              left: "2%",
              zIndex: "-1",
              opacity: "0.5",
            }}
          >
            <img
              src="/Images/footer/shape-2.png"
              className="rotateSlow"
              alt=""
            />
          </div>
          <div
            className="shape2 footerAnimation"
            style={{
              position: "absolute",
              top: "-190px",
              left: "7%",
              zIndex: "-1",
              opacity: "0.5",
            }}
          >
            <img
              src="/Images/footer/shape-3.png"
              className="rotateSlow"
              alt=""
            />
          </div>
          <div
            className="shape2 footerAnimation"
            style={{
              position: "absolute",
              top: "-50px",
              left: "37%",
              zIndex: "-1",
            }}
          >
            <img
              src="/Images/footer/shape-4.png"
              className="rotateSlow"
              alt=""
            />
          </div>
          <div
            className="shape2 footerAnimation"
            style={{
              position: "absolute",
              top: "-10px",
              right: "24%",
              zIndex: "-1",
            }}
          >
            <img
              src="/Images/footer/shape-5.png"
              className="rotateSlow"
              alt=""
            />
          </div>
        </div>
        <div className="container2">
          <div className={styles.upper_grid}>
            <div className={`${styles.gridItem} ${styles.gridItem1}`}>
              <div className={styles.logo}>
                <a href="/">
                  <img src="/Images/logo.webp" alt="" />
                </a>
              </div>
              <p>
                Best solution for your it startup business.
              </p>
              <ul>
                {/* <li>
                  <span style={{ color: "#0064fb" }}>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 24 24"
                      fill="currentColor"
                      width={17}
                    >
                      <g>
                        <path fill="none" d="M0 0h24v24H0z"></path>
                        <path d="M22 17.002a6.002 6.002 0 0 1-4.713 5.86l-.638-1.914A4.003 4.003 0 0 0 19.465 19H17a2 2 0 0 1-2-2v-4a2 2 0 0 1 2-2h2.938a8.001 8.001 0 0 0-15.876 0H7a2 2 0 0 1 2 2v4a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2v-5C2 6.477 6.477 2 12 2s10 4.477 10 10V17.002zM20 17v-4h-3v4h3zM4 13v4h3v-4H4z"></path>
                      </g>
                    </svg>
                  </span>
                  <a href="tel:8989898989">(312) 895-9800</a>
                </li> */}
                <li>
                  <span style={{ color: "#FF414B" }}>
                    <svg
                      className="w-64 h-64"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 24 24"
                      width={17}
                      fill="currentColor"
                    >
                      <g>
                        <path fill="none" d="M0 0h24v24H0z"></path>
                        <path d="M12 22C6.477 22 2 17.523 2 12S6.477 2 12 2s10 4.477 10 10-4.477 10-10 10zm-2.29-2.333A17.9 17.9 0 0 1 8.027 13H4.062a8.008 8.008 0 0 0 5.648 6.667zM10.03 13c.151 2.439.848 4.73 1.97 6.752A15.905 15.905 0 0 0 13.97 13h-3.94zm9.908 0h-3.965a17.9 17.9 0 0 1-1.683 6.667A8.008 8.008 0 0 0 19.938 13zM4.062 11h3.965A17.9 17.9 0 0 1 9.71 4.333 8.008 8.008 0 0 0 4.062 11zm5.969 0h3.938A15.905 15.905 0 0 0 12 4.248 15.905 15.905 0 0 0 10.03 11zm4.259-6.667A17.9 17.9 0 0 1 15.973 11h3.965a8.008 8.008 0 0 0-5.648-6.667z"></path>
                      </g>
                    </svg>
                  </span>
                  hello@webneuron.io
                </li>
                <li>
                  <span style={{ color: "#36CC72" }}>
                    <svg
                      className="w-64 h-64"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 24 24"
                      width={17}
                      fill="currentColor"
                    >
                      <g>
                        <path fill="none" d="M0 0h24v24H0z"></path>
                        <path d="M12 20.9l4.95-4.95a7 7 0 1 0-9.9 0L12 20.9zm0 2.828l-6.364-6.364a9 9 0 1 1 12.728 0L12 23.728zM12 13a2 2 0 1 0 0-4 2 2 0 0 0 0 4zm0 2a4 4 0 1 1 0-8 4 4 0 0 1 0 8z"></path>
                      </g>
                    </svg>
                  </span>
                  616, Fortune Ambience, South Tukoganj, Indore, India
                </li>
              </ul>
            </div>
            <div className={`${styles.gridItem} ${styles.gridItem2}`}>
              <h3>Quick Link</h3>
              <ul>
                <li>
                  <div onClick={scrollToAbout}>About Us</div>
                </li>
                <li>
                  <Link to="/contact">Contact</Link>
                </li>

                <li>
                  <a href="https://www.linkedin.com/company/webneuron-solution-pvt-ltd" target="_blank">
                    <i>
                      <svg
                        className="w-64 h-64"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 24 24"
                        width={20}
                        fill="currentColor"
                      >
                        <g>
                          <path fill="none" d="M0 0h24v24H0z"></path>
                          <path
                            fillRule="nonzero"
                            d="M6.94 5a2 2 0 1 1-4-.002 2 2 0 0 1 4 .002zM7 8.48H3V21h4V8.48zm6.32 0H9.34V21h3.94v-6.57c0-3.66 4.77-4 4.77 0V21H22v-7.93c0-6.17-7.06-5.94-8.72-2.91l.04-1.68z"
                          ></path>
                        </g>
                      </svg>
                    </i>
                  </a>
                </li>
              </ul>
            </div>
            <div className={`${styles.gridItem} ${styles.gridItem3}`}>
              <h3>Services</h3>
              <ul>
                <li>
                  <a href="pricing.html">Web Application Development</a>
                </li>
                <li>
                  <a href="pricing.html">Blockchain based Solutions/NFT</a>
                </li>
                <li>
                  <a href="pricing.html">Mobile App Development</a>
                </li>
                <li>
                  <a href="pricing.html">Front End Development</a>
                </li>
              </ul>{" "}
            </div>
          </div>
        </div>
      </div>
      <div className={styles.LowerFooter}>
        <div className="container2">
          <div className={styles.lowerContent}>
            <p>
              Copyright © {new Date().getFullYear()} Webneuron Solution Pvt Ltd. All Rights Reserved.
            </p>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
