import React from "react";
import Navbar from "../components/Navbar";
import Heading from "../components/UI/Heading";
import TopHeader from "../components/UI/TopHeader";
import Service from "../components/serviceUi/Service";
import Support from "../components/serviceUi/Support";
import Clints from "../components/serviceUi/Clints";
import GetStarted from "../components/serviceUi/GetStarted";
import Footer from "../components/serviceUi/Footer";
import Process from "../components/serviceUi/Process";
import BacktoTop from "../components/shortCSS/BacktoTop";
import strings from '../constants/strings';
import Strip from '../components/serviceUi/Strip';

const Services1 = () => {
  const data = [
    {
      id: 1,
      iconNumber: 1,
      title: "MERN",
      description: "Webneuron offering you the best digital marketing services to just like your personal identity makes you uniquely you, your brand identity is the special sauce of your to our customer.",
      link: "",
      themeColor: "#0064fb",
      themeSmallColor: "#e5f0ff",
      textColor: "#fff",
      icon: <svg className="w-64 h-64" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor">
        <g>
          <path fill="none" d="M0 0h24v24H0z"></path>
          <path d="M6.94 14.036c-.233.624-.43 1.2-.606 1.783.96-.697 2.101-1.139 3.418-1.304 2.513-.314 4.746-1.973 5.876-4.058l-1.456-1.455 1.413-1.415 1-1.001c.43-.43.915-1.224 1.428-2.368-5.593.867-9.018 4.292-11.074 9.818zM17 9.001L18 10c-1 3-4 6-8 6.5-2.669.334-4.336 2.167-5.002 5.5H3C4 16 6 2 21 2c-1 2.997-1.998 4.996-2.997 5.997L17 9.001z"></path>
        </g>
      </svg>,
    },
    {
      id: 2,
      iconNumber: 2,
      title: "React Native/Angular/Veu",
      description: "Webneuron offering you the best digital marketing services to just like your personal identity makes you uniquely you, your brand identity is the special sauce of your to our customer.",
      link: "",
      themeColor: "#36cc72",
      themeSmallColor: "#ebfaf1",
      textColor: "#fff",
      icon: <svg className="w-64 h-64" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor">
        <g>
          <path fill="none" d="M0 0h24v24H0z"></path>
          <path d="M12 22C6.477 22 2 17.523 2 12c0-4.478 2.943-8.268 7-9.542v2.124A8.003 8.003 0 0 0 12 20a8.003 8.003 0 0 0 7.418-5h2.124c-1.274 4.057-5.064 7-9.542 7zm9.95-9H11V2.05c.329-.033.663-.05 1-.05 5.523 0 10 4.477 10 10 0 .337-.017.671-.05 1zM13 4.062V11h6.938A8.004 8.004 0 0 0 13 4.062z"></path>
        </g>
      </svg>,
    },
    {
      id: 3,
      iconNumber: 3,
      title: "Framework - 1. Laravel 2. Wordpress 3. Woocommerce 4. Open source 5. Shopify",
      description: "Webneuron offering you the best digital marketing services to just like your personal identity makes you uniquely you, your brand identity is the special sauce of your to our customer.",
      link: "",
      themeColor: "#ff414b",
      themeSmallColor: "#ffeced",
      textColor: "#fff",
      icon: <svg className="w-64 h-64" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor">
        <g>
          <path fill="none" d="M0 0h24v24H0z"></path>
          <path d="M9.973 18H11v-5h2v5h1.027c.132-1.202.745-2.194 1.74-3.277.113-.122.832-.867.917-.973a6 6 0 1 0-9.37-.002c.086.107.807.853.918.974.996 1.084 1.609 2.076 1.741 3.278zM10 20v1h4v-1h-4zm-4.246-5a8 8 0 1 1 12.49.002C17.624 15.774 16 17 16 18.5V21a2 2 0 0 1-2 2h-4a2 2 0 0 1-2-2v-2.5C8 17 6.375 15.774 5.754 15z"></path>
        </g>
      </svg>,
    },
  ]
  return (
    <div className="ServicesPages" id="ServicePages">
      {/* <TopHeader /> */}
      <Navbar />
      <Heading subject={strings.service1.heading} />
      <div className="container">
        <Service data={data} />
      </div>
      <Process />
      <Strip title={"We would love to hear about your project. Get the best services for Web Application Development."} />
      <Support strings={strings.service1} />
      <Clints />
      <GetStarted />
      <BacktoTop />
      <Footer />
    </div>
  );
};

export default Services1;
