import React from "react";

const Star = () => {
  return (
    <svg
      className="w-64 h-64"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      width={20}
      fill="currentColor"
    >
      <g>
        <path fill="none" d="M0 0h24v24H0z"></path>
        <path d="M12 18.26l-7.053 3.948 1.575-7.928L.587 8.792l8.027-.952L12 .5l3.386 7.34 8.027.952-5.935 5.488 1.575 7.928z"></path>
      </g>
    </svg>
  );
};

export default Star;
