import React from 'react'
import styles from "../../styles/Service.module.css";
import { Link } from 'react-router-dom';
import { ArrowForward } from "@mui/icons-material";

const ServiceCard = ({ data }) => {

    return (
        <div className={`${styles.service_grid_item} `} style={{ display: "flex", flexDirection: "column", alignItems: "center", }}>
            <div className={`${styles.iconBox} ${styles["service_item" + data.iconNumber]}`} style={{}}>
                <div className={styles.icon}>
                    {data.icon}
                </div>
            </div>
            <h3>
                <a href="/">{data.title}</a>
            </h3>
            <p style={{ textAlign: "center" }}>{data.description}</p>
            {/* <Link to={data.link} className={styles.service_btn}>
                Read More
                <ArrowForward
                    fontSize="14px"
                    style={{ marginBottom: "-3px", fontWeight: "800", marginLeft: "4px" }}
                />
            </Link> */}
        </div>
    )
}

export default ServiceCard;