import React from 'react'
import styles from "../../styles/ServiceDetail.module.css";
import { ArrowForward } from "@mui/icons-material";
import { Link } from 'react-router-dom';

const ServiceDetailCard = ({ children, heading, description, imageUrl, link }) => {
    return (
        <div className={styles.ServiceDetailBoxItem}>
            {children}
            <div className={styles.imgBox}>
                <Link to={link}>
                    <img src={imageUrl} alt="" />
                </Link>
            </div>
            <h3>
                <Link to={link}>{heading}</Link>
            </h3>
            <p>
                {description}
            </p>
            <Link
                to={link}
                className={`${styles.featureButton} ${styles.button}  Animated Animatedbutton `}
            >
                Read More &nbsp;
                <ArrowForward
                    fontSize="14px"
                    style={{ marginBottom: "-3px", fontWeight: "800" }}
                />{" "}
            </Link>{" "}
        </div>
    )
}

export default ServiceDetailCard;