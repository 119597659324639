import { useEffect, useState } from "react";
import styles from "../styles/Navbar.module.css";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import SmallScreen from "./SmallScreen";
import MessageOutlinedIcon from "@mui/icons-material/MessageOutlined";
import { Link } from "react-router-dom";
import { EmailOutlined } from '@mui/icons-material';

const Navbar = ({ aboutRef }) => {
  const [isScroll, setIsScroll] = useState(false);
  const [show, setShow] = useState(false);
  const [othersBox, setOthersBox] = useState(false);
  const [bigScreen, setBigScreen] = useState(false);

  useEffect(() => {
    if (window.innerWidth >= 1200) {
      setBigScreen(true);
      setOthersBox(true);
    } else if (window.innerWidth < 1200) {
      setBigScreen(false);
      setOthersBox(false);
    }
    window.addEventListener("scroll", () => {
      if (window.pageYOffset >= 400) {
        setIsScroll(true);
      } else if (window.pageYOffset < 400) {
        setIsScroll(false);
      }
    });
  }, []);
  const scrollToAbout = e => {
    if (aboutRef.current) {
      window.scrollTo({ top: aboutRef.current.offsetTop, behavior: "smooth" });
    }
  }
  return (
    <nav className={`${styles.nav} ${isScroll && styles.navFixed}`}>
      <div className="container">
        <div className={styles.navContent}>
          <Link to="/" className={styles.imageContainer}>
            <img
              src="/Images/logo.webp"
              width={120}
              height={55}
              alt=""
              className={styles.logo}
            />
          </Link>
          {bigScreen ? (
            <ul className={styles.navUl}>
              <li>
                <Link to={"/"}>Home</Link>
              </li>
              <li>
                <div onClick={scrollToAbout}>
                  About
                </div>
              </li>
              <li>
                <Link to={"/"}>
                  Services <span> +</span>
                </Link>
                <ul className={styles.dropDown}>
                  <li>
                    <Link to="/web-application-development">Web Application Development</Link>
                  </li>
                  <li>
                    <Link to="/blockchain-based-solutions">Blockchain based Solutions / NFT</Link>
                  </li>
                  <li>
                    <Link to="/mobile-app-development">Mobile App Development</Link>
                  </li>
                  <li>
                    <Link to="/front-end-development">Front End Development</Link>
                  </li>
                </ul>
              </li>
              {/* <li>
                <Link to={"/"}>
                  Pages <span> +</span>
                </Link>

                <ul className={styles.dropDown}>
                  <li>
                    <Link to={"/Demo"}>Team Member</Link>
                  </li>
                  <li>
                    <Link to={"/Demo"}>Pricing</Link>
                  </li>
                  <li>
                    <Link to={"/Demo"}>Gallery</Link>
                  </li>
                  <li>
                    <Link to={"/Demo"}>FAQ</Link>
                  </li>
                  <li style={{ display: "flex", alignItems: "center" }}>
                    <Link to={"/Demo"}>Events</Link>
                    <span
                      style={{
                        position: "absolute",
                        right: "10px",
                        paddingTop: "5px",
                        fontSize: "12px",
                      }}
                    >
                      <ChevronRightIcon />
                    </span>
                  </li>
                  <li style={{ display: "flex", alignItems: "center" }}>
                    <Link to={"/Demo"}>Courses</Link>
                    <span
                      style={{
                        position: "absolute",
                        right: "10px",
                        paddingTop: "5px",
                        fontSize: "12px",
                      }}
                    >
                      <ChevronRightIcon />
                    </span>
                  </li>
                  <li style={{ display: "flex", alignItems: "center" }}>
                    <Link to={"/Demo"}>Shop</Link>
                    <span
                      style={{
                        position: "absolute",
                        right: "10px",
                        paddingTop: "5px",
                        fontSize: "12px",
                      }}
                    >
                      <ChevronRightIcon />
                    </span>
                  </li>
                  <li>
                    <Link to={"/Demo"}>Profile Authentication</Link>
                  </li>
                  <li>
                    <Link to={"/Demo"}>Membership Levels</Link>
                  </li>
                  <li>
                    <Link to={"/Demo"}>Purchase Guide</Link>
                  </li>
                  <li>
                    <Link to={"/Demo"}>Terms of Service</Link>
                  </li>
                  <li>
                    <Link to={"/Demo"}>Privacy Policy</Link>
                  </li>
                  <li>
                    <Link to={"/Demo"}>404 Error</Link>
                  </li>
                  <li>
                    <Link to={"/Demo"}>Coming Soon </Link>
                  </li>
                </ul>
              </li>
              <li>
                <Link to={"/"}>
                  Case Study <span> +</span>
                </Link>

                <ul className={styles.dropDown}>
                  <li>
                    <Link to="/case-study/case-study-1">Case Study one</Link>
                  </li>
                  <li>
                    <Link to="/case-study/case-study-2">Case Study two</Link>
                  </li>
                  <li>
                    <Link to="/case-study/case-study-3">
                      Case Study Details
                    </Link>
                  </li>
                </ul>
              </li>
              <li>
                <Link to={"/"}>
                  Blog <span> +</span>
                </Link>
                <ul className={styles.dropDown}>
                  <li>
                    <Link to="/blog/blog">Blog</Link>
                  </li>
                  <li>
                    <Link to="/blog/blogRightSidebar">Blog Right Sidebar </Link>
                  </li>
                  <li>
                    <Link to="/blog/blog-standard">Blog Standard </Link>
                  </li>
                  <li>
                    <Link to="/blog/blog-details">Blog Details </Link>
                  </li>
                </ul>
              </li> */}
              <li>
                <Link to={"/contact"}>Contact</Link>
              </li>
            </ul>
          ) : (
            <SmallScreen show={show} setShow={setShow} aboutRef={aboutRef} />
          )}
          <div
            className={styles.otherOptions}
            id="otherOptions"
            style={{
              opacity: othersBox ? 1 : 0,
              transform: othersBox ? "scaleX(1)" : "scaleX(0)",
            }}
          >
            <div className={styles.option2}>
              <a className='site_red' href="mailto:hello@webneuron.io" style={{ display: "flex", alignItems: "center", gap: "5px", }}> <EmailOutlined /> hello@webneuron.io</a>
            </div>
          </div>
          {!bigScreen && (
            <div className={styles.hamBurgersBox}>
              {!show ? (
                <div
                  className={styles.menuHamburgerLine}
                  onClick={() => setShow(!show)}
                >
                  <div className={styles.menuHamItem}></div>
                  <div className={styles.menuHamItem}></div>
                  <div className={styles.menuHamItem}></div>
                </div>
              ) : (
                <div
                  className={styles.menuHamburgerCross}
                  onClick={() => setShow(!show)}
                >
                  <span>X</span>
                </div>
              )}
            </div>
          )}
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
