import React from 'react'
import styles from "../../styles/GetStarted.module.css";

const Strip = ({ title }) => {
    return (
        <div className={styles.strip} id="Strip">
            <div className={styles.container}>
                <div className={styles.strip_container}>
                    <div className={styles.stripContent}>
                        <div>{title}</div>
                        <div style={{ background: "#fff", color: "#000", padding: "5px 10px" }}>
                            <a href="" className={styles.strip_btn}>Email Now</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Strip;
