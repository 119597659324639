import React from "react";

const Message = () => {
  return (
    <svg
      className="w-64 h-64"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      fill="currentColor"
      width={14}
    >
      <g>
        <path fill="none" d="M0 0h24v24H0z"></path>
        <path d="M6.455 19L2 22.5V4a1 1 0 0 1 1-1h18a1 1 0 0 1 1 1v14a1 1 0 0 1-1 1H6.455zm-.692-2H20V5H4v13.385L5.763 17zM11 10h2v2h-2v-2zm-4 0h2v2H7v-2zm8 0h2v2h-2v-2z"></path>
      </g>
    </svg>
  );
};

export default Message;
