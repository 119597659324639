import "./App.css";
import Home from "./pages/Home";
import About1 from "./pages/About1";
import { Routes, Route, useLocation } from "react-router-dom";
import Services1 from "./pages/Services1";
import Services2 from "./pages/Services2";
import Services3 from "./pages/Services3";
import Contact from "./pages/Contact";
import { useEffect, useRef } from 'react';
import Services4 from './pages/Services4';

function App() {
  const location = useLocation()
  const aboutRef = useRef()
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, [location])

  return (
    <div className="App">
      <Routes>
        <Route path="/" element={<Home />} />
        {/* <Route path="/about1" element={<About1 />} /> */}
        <Route path="/web-application-development" element={<Services1 />} />
        <Route path="/blockchain-based-solutions" element={<Services2 />} />
        <Route path="/mobile-app-development" element={<Services3 />} />
        <Route path="/front-end-development" element={<Services4 />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="*" element={<div id='about' ref={aboutRef}>
          <Home />
        </div>} />
      </Routes>
    </div>
  );
}

export default App;
