import React, { useState } from "react";
import styles from "./shortCSS/smallScreen.module.css";
import { Link } from "react-router-dom";

const SmallScreen = ({ show, setShow, aboutRef }) => {
  const [serviceExpand, setserviceExpand] = useState(true);
  const scrollToAbout = () => {
    setShow(false);
    if (aboutRef.current) {
      window.scrollTo({ top: aboutRef.current.offsetTop, behavior: "smooth" });
    }
  }
  return (
    <div
      className={styles.SmallScreen}
      style={{ height: show ? "auto" : "0.001px" }}
    >
      <ul className={styles.navSmallUl}>
        <li>
          <Link to={"/"}>Home</Link>
        </li>
        <li>
          <div className={styles.navLink} onClick={scrollToAbout}>About</div>
        </li>
        <li onClick={() => setserviceExpand(!serviceExpand)}>
          <div className={styles.navLink} to={""}>
            Services
            {serviceExpand ? (
              <span style={{ fontSize: "18px", fontWeight: "700" }}> +</span>
            ) : (
              <span style={{ fontSize: "18px", fontWeight: "700" }}> -</span>
            )}
          </div>

          <ul
            className={styles.dropDown}
            style={{ height: serviceExpand ? 0 : "fit-content" }}
          >
            <li>
              <Link to="/web-application-development">Web Application Development</Link>
            </li>
            <li>
              <Link to="/blockchain-based-solutions">Blockchain based Solutions / NFT</Link>
            </li>
            <li>
              <Link to="/mobile-app-development">Mobile App Development</Link>
            </li>
            <li>
              <Link to="/front-end-development">Front End Development</Link>
            </li>
          </ul>
        </li>
        <li>
          <Link to={"/contact"}>Contact</Link>
        </li>
      </ul>
    </div>
  );
};

export default SmallScreen;
